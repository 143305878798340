import React from "react";
import {
  Box,
  IconButton,
  MenuItem,
  MenuList,
  Popover,
  Typography,
  CircularProgress
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import AddIcCallIcon from "@mui/icons-material/Call";
import ClearIcon from "@mui/icons-material/Clear";
import { colors } from "../../app/theme/colors";
import PublicIcon from "@mui/icons-material/Public";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import CollectionsIcon from "@mui/icons-material/Collections";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";

interface Props {
  handleClickCube: any;
  cubeType: any;
  handleOpenMenu: any;
  selectedItem: any;
  clickedCube: any;
  anchorEl: any;
  handleClose: any;
  options: any;
  handleSelectItem: any;
  isActive: boolean;
  handleClearItem: any;
  companyWebsiteStatus: string;
}

export default function MySiteSectionCube({
  cubeType,
  handleClickCube,
  handleOpenMenu,
  selectedItem = {},
  clickedCube,
  anchorEl,
  handleClose,
  options,
  handleSelectItem,
  isActive,
  handleClearItem,
  companyWebsiteStatus
}: Props) {
  const { value = "default" } = selectedItem;

  return (
    <Box
      sx={{
        ml: value === "first" ? 0 : 1,
        height: 100,
        width: 100,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        background:
          value === "Photo" ||
          value === "SocialMedia" ||
          value === "Text" ||
          value === "Video" ||
          value === "Gallery" ||
          value === "Slider" ||
          value === "Phone"
            ? "rgba(75, 138, 244, 0.25)"
            : "rgba(75, 138, 244, 1)",
        position: "relative",
      }}
      onClick={() => handleClickCube(cubeType)}
    >
      <IconButton onClick={handleOpenMenu(cubeType)}>
        {companyWebsiteStatus === "Pending" ? (
          <CircularProgress color="inherit" />
        ) : value === "Photo" ? (
          <AddAPhotoIcon
            sx={{ color: "rgba(75, 138, 244, 0.8)", fontSize: "40px" }}
          />
        ) : value === "Text" ? (
          <TextFieldsIcon
            sx={{ color: "rgba(75, 138, 244, 0.8)", fontSize: "40px" }}
          />
        ) : value === "Phone" ? (
          <AddIcCallIcon
            sx={{ color: "rgba(75, 138, 244, 0.8)", fontSize: "40px" }}
          />
        ) : value === "SocialMedia" ? (
          <PublicIcon
            sx={{ color: "rgba(75, 138, 244, 0.8)", fontSize: "40px" }}
          />
        ) : value === "Video" ? (
          <VideoCallIcon
            sx={{ color: "rgba(75, 138, 244, 0.8)", fontSize: "40px" }}
          />
        ) : value === "Gallery" ? (
          <CollectionsIcon
            sx={{ color: "rgba(75, 138, 244, 0.8)", fontSize: "40px" }}
          />
        ) : value === "Slider" ? (
          <ViewCarouselIcon
            sx={{ color: "rgba(75, 138, 244, 0.8)", fontSize: "40px" }}
          />
        ) : (
          <AddIcon sx={{ color: colors.white, fontSize: "40px" }} />
        )}
      </IconButton>

      <Popover
        id={`mainHeader_popover_services_${cubeType}`}
        open={clickedCube === cubeType && Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuList>
          {options.map((option: any) => (
            <MenuItem
              key={option.value}
              onClick={() => handleSelectItem(option.value)}
            >
              <Typography variant="body1" sx={{ textTransform: "none" }}>
                {option.label}
              </Typography>
            </MenuItem>
          ))}
        </MenuList>
      </Popover>
      {isActive && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            zIndex: 1,
          }}
        >
          <IconButton
            onClick={() => {
              if (
                selectedItem &&
                selectedItem.orderNumber !== undefined &&
                selectedItem.orderNumber !== null
              ) {
                handleClearItem(
                  selectedItem.orderNumber,
                  selectedItem.value,
                  selectedItem.id
                );
              }
            }}
          >
            <ClearIcon sx={{ color: "rgba(75, 138, 244, 0.8)" }} />
          </IconButton>
        </Box>
      )}
      {isActive && (
        <Box
          sx={{
            position: "absolute",
            width: 0,
            height: 0,
            borderLeft: "5px solid transparent",
            borderRight: "5px solid transparent",
            borderBottom: `5px solid ${colors.primary}`,
            bottom: -5,
            left: "50%",
            transform: "translateX(-50%)",
          }}
        />
      )}
    </Box>
  );
}
