import {
  Avatar,
  Box,
  Card,
  CircularProgress,
  DialogContent,
  Grid,
  IconButton,
  Link,
  Popover,
  Tooltip,
  Typography,
} from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import { useSearchResultLayoutStyles } from "./searchResultLayoutStyles";
import { CompanyModel } from "../../models/company/companyModel";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import StarIcon from "@mui/icons-material/Star";
import PublicIcon from "@mui/icons-material/Public";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTiktok } from "@fortawesome/free-brands-svg-icons";
import { useTranslation } from "react-i18next";
import { CompanySocialMediaModel } from "../../models/company/companySocialMediaModel";
import AppButton from "../../components/appButton/appButton";
import { colors } from "../../app/theme/colors";
import { useAppDispatch, useAppSelector } from "../../app/redux/hooks";
import {
  selectCompanyById,
  selectLoadingFullDetailsStatus,
} from "../../slices/companySlice";
import { Env_UM } from "../../app/redux/api_um";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import { useEffect, useState } from "react";
import { selectCurrentAccount } from "../../slices/authSlice";
import SearchResultsActionsRequest from "../../models/searchResultsActions/searchResultsActionsRequest";
import { INIT_SEARCH_RESULTS_ACTIONS } from "../../models/init_data/initData";
import {
  removeCategory,
  removeClient,
  removeFavorite,
  removePartner,
  removePotentialPartner,
  saveCategory,
  saveClient,
  saveFavorite,
  savePartner,
  savePotentialPartner,
  selectCategoryStatus,
  selectClientStatus,
  selectPartnerStatus,
  selectPotentialPartnerStatus,
  selectRemovedFavoriteStatus,
} from "../../slices/searchResultsActionsSlice";
import SearchResultLayoutPopoverMenu from "./searchResultsLayoutPopoverMenu";
import OverallRatingModal from "../modals/overallRatingModal/overallRatingModal";
import { getCompanyRating } from "../../slices/overallRatingSlice";
import { toast } from "react-toastify";
import { setSuccessNotification } from "../../slices/notificationSlice";
import i18n from "../../i18n";

interface Props {
  company?: CompanyModel | null;
  onClick?: any;
  setRemovedFavoirteId: any;
  selectedCompany?: CompanyModel;
  setSelectedCompany: any;
  setAddFavoriteId: any;
  overallRatingStatus: string;
}

interface ClickedButtonsState {
  partner: boolean;
  potentialPartner: boolean;
  client: boolean;
  saveActivity: boolean;
  favorite: boolean;
}

export default function SearchResultLayout({
  company,
  onClick,
  setRemovedFavoirteId,
  setSelectedCompany,
  setAddFavoriteId,
  selectedCompany,
  overallRatingStatus,
}: Props) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { classes } = useSearchResultLayoutStyles();
  const fullDetailsStatus = useAppSelector(selectLoadingFullDetailsStatus);
  const currentAccount = useAppSelector(selectCurrentAccount);
  const potentialPartnerStatus = useAppSelector(selectPotentialPartnerStatus);
  const clientStatus = useAppSelector(selectClientStatus);
  const savePartnerStatus = useAppSelector(selectPartnerStatus);
  const saveActivityStatus = useAppSelector(selectCategoryStatus);
  const companyById = useAppSelector(selectCompanyById);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const openSettings = Boolean(anchorEl);

  const [isOpenOverallRatingModal, setIsOpenOverallRatingModal] =
    useState<boolean>(false);

  const [open, setOpen] = useState(false);

  const [savedPartner, setSavedPartner] =
    useState<SearchResultsActionsRequest | null>(null);

  const [companyForRating, setCompanyForRating] = useState<CompanyModel | null>(
    null
  );

  const [isFavoriteRemoved, setIsFavoriteRemoved] = useState<boolean>(false);

  const [clickedButtons, setClickedButtons] = useState<ClickedButtonsState>({
    partner: false,
    potentialPartner: false,
    client: false,
    saveActivity: false,
    favorite: false,
  });

  const handleButtonClick = (buttonName: keyof ClickedButtonsState) => {
    setSelectedCompany(company);
    if (company) {
      switch (buttonName) {
        case "partner":
          setClickedButtons((prevState) => ({
            ...prevState,
            partner: true,
          }));
          dispatch(savePartner(company?.generalData.id));
          break;
        case "potentialPartner":
          setClickedButtons((prevState) => ({
            ...prevState,
            potentialPartner: true,
          }));
          dispatch(savePotentialPartner(company?.generalData.id));
          break;
        case "client":
          setClickedButtons((prevState) => ({
            ...prevState,
            client: true,
          }));
          dispatch(saveClient(company?.generalData.id));
          break;
        case "favorite":
          setIsFavoriteRemoved(true);
          setAddFavoriteId(company.generalData.id);
          dispatch(saveFavorite(company?.generalData.id));
          break;
        default:
          break;
      }
    }
  };

  const handleButtonClickRemove = (buttonName: keyof ClickedButtonsState) => {
    setSelectedCompany(company);
    if (company) {
      switch (buttonName) {
        case "partner":
          setClickedButtons((prevState) => ({
            ...prevState,
            partner: false,
          }));
          dispatch(removePartner(company?.generalData.id));
          break;
        case "potentialPartner":
          setClickedButtons((prevState) => ({
            ...prevState,
            potentialPartner: false,
          }));
          dispatch(removePotentialPartner(company?.generalData.id));
          break;
        case "client":
          setClickedButtons((prevState) => ({
            ...prevState,
            client: false,
          }));
          dispatch(removeClient(company?.generalData.id));
          break;
        case "saveActivity":
          dispatch(removeCategory(company?.generalData.id));

          break;
        case "favorite":
          setIsFavoriteRemoved(false);
          setAddFavoriteId(company.generalData.id);
          dispatch(removeFavorite(company?.generalData.id));
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    if (savePartnerStatus === "Fulfilled") {
      setSavedPartner(INIT_SEARCH_RESULTS_ACTIONS);
    }
  }, [savePartnerStatus]);

  const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const stars = Array(5)
    .fill(null)
    .map((_, i) => {
      if (i < Math.floor(company?.generalData?.numberOfStars ?? 0)) {
        return (
          <StarIcon
            key={i}
            sx={{
              color:
                company?.generalData.isPremium === true ? "#e6dd00" : "#F0E700",
              fontSize: "24px",
            }}
          />
        );
      } else {
        return null;
      }
    });

  const handleSocialMediaClick = (event: any, url: any) => {
    event.stopPropagation();
    window.open(url, "_blank");
  };
  const handleShare = (websiteName: string) => {
    const url = `http://localhost:3000/${websiteName}`;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        setOpen(true);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (company?.generalData.isFavorite === false) {
      setIsFavoriteRemoved(false);
    } else {
      setIsFavoriteRemoved(true);
    }
    if (
      company?.generalData.isPartnerCompany === true &&
      savePartnerStatus === "Fulfilled"
    ) {
      setClickedButtons((prevState) => ({
        ...prevState,
        partner: true,
      }));
    } else if (company?.generalData.isPartnerCompany === false) {
      setClickedButtons((prevState) => ({
        ...prevState,
        partner: false,
      }));
    }
    if (company?.generalData.isPotentialPartnerCompany === true) {
      setClickedButtons((prevState) => ({
        ...prevState,
        potentialPartner: true,
      }));
    } else if (company?.generalData.isPotentialPartnerCompany === false) {
      setClickedButtons((prevState) => ({
        ...prevState,
        potentialPartner: false,
      }));
    }
    if (company?.generalData.isCompanyClient === true) {
      setClickedButtons((prevState) => ({
        ...prevState,
        client: true,
      }));
    } else if (company?.generalData.isCompanyClient === false) {
      setClickedButtons((prevState) => ({
        ...prevState,
        client: false,
      }));
    }
    if (company?.generalData.isCompanyClient === true) {
      setClickedButtons((prevState) => ({
        ...prevState,
        saveActivity: true,
      }));
    } else if (company?.generalData.isCompanyClient === false) {
      setClickedButtons((prevState) => ({
        ...prevState,
        saveActivity: false,
      }));
    }
  }, []);

  useEffect(() => {
    if (companyById) {
      dispatch(getCompanyRating(companyById?.generalData.id));
    }
  }, [companyById]);

  useEffect(() => {
    if (overallRatingStatus === "Fulfilled") {
      setIsOpenOverallRatingModal(false);
    }
  }, [overallRatingStatus]);

  useEffect(() => {
    if (open) {
      dispatch(
        setSuccessNotification(i18n.t("searchResultLayout.cardShareMessage"))
      );
      setOpen(false);
    }
  }, [open]);

  return (
    <Card
      id="searchResultLayout_card"
      className={`${classes.card} ${
        company?.generalData.isPremium === true
          ? classes.paidCard
          : classes.card
      }`}
      sx={{ padding: "10px", marginTop: "10px" }}
    >
      <Grid container>
        <Grid item lg={3} md={6} xs={12} sm={12}>
          <Box sx={{ position: "relative" }}>
            {company?.generalData.numberOfStars != null && (
              <Grid container display={"flex"} justifyContent={"center"}>
                {stars}
              </Grid>
            )}
            {company?.generalData.logoBase64 === null ? (
              <Grid
                sx={{ padding: "10px", textAlign: "center" }}
                display={"flex"}
                justifyContent={"center"}
              >
                <Avatar className={classes.avatar}>
                  {company?.generalData.name
                    ? company.generalData.name
                        .split(" ")
                        .slice(0, 1)
                        .map((word: any) => word[0])
                        .join("")
                        .toUpperCase()
                    : ""}
                </Avatar>
              </Grid>
            ) : (
              <Grid
                sx={{
                  padding: "10px",
                }}
              >
                <CardMedia
                  id="searchResultLayout_img_logo"
                  component="img"
                  className={classes.cardMedia}
                  image={Env_UM.Prod + "/" + company?.generalData?.logoBase64}
                  style={{
                    backgroundColor: "inherit",
                    position: "relative",
                  }}
                />
              </Grid>
            )}

            {company?.generalData.isPremium === true && (
              <Box className={classes.premiumMarkWrapper}>
                <Typography variant="body2">
                  {t("searchResultLayout.cardPremium")}
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item lg={9} md={12}>
          <Grid container>
            <Grid item md={6}>
              <Typography variant="h5">{company?.generalData?.name}</Typography>
            </Grid>
            <Grid item md={6} display="flex" justifyContent={"flex-end"}>
              {currentAccount ? (
                <>
                  <IconButton onClick={handleOpenPopover}>
                    <MoreHorizIcon sx={{ fontSize: "30px" }} />
                  </IconButton>
                  <Popover
                    open={openSettings && Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClosePopover}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <DialogContent sx={{ padding: "10px" }}>
                      <Grid
                        container
                        justifyContent="center"
                        alignItems={"center"}
                        direction={"column"}
                        sx={{ width: "100%" }}
                      >
                        <SearchResultLayoutPopoverMenu
                          isCategory={false}
                          buttonTitle="partner"
                          clickedButtons={clickedButtons.partner}
                          handleButtonClick={handleButtonClick}
                          handleButtonClickRemove={handleButtonClickRemove}
                          savePartnerStatus={savePartnerStatus}
                          displayTitle={t(
                            "dashboardCard.dashboardCardAssociateTitle"
                          )}
                        />
                        <SearchResultLayoutPopoverMenu
                          isCategory={false}
                          buttonTitle="potentialPartner"
                          clickedButtons={clickedButtons.potentialPartner}
                          handleButtonClick={handleButtonClick}
                          handleButtonClickRemove={handleButtonClickRemove}
                          savePartnerStatus={potentialPartnerStatus}
                          displayTitle={t(
                            "dashboardCard.dashboardCardPotentialAssociateTitle"
                          )}
                        />
                        <SearchResultLayoutPopoverMenu
                          isCategory={false}
                          buttonTitle="client"
                          clickedButtons={clickedButtons.client}
                          handleButtonClick={handleButtonClick}
                          handleButtonClickRemove={handleButtonClickRemove}
                          savePartnerStatus={clientStatus}
                          displayTitle={t(
                            "dashboardCard.dashboardCardClientTitle"
                          )}
                        />
                        {/* <SearchResultLayoutPopoverMenu


                          isCategory={true}
                          buttonTitle="saveActivity"
                          clickedButtons={clickedButtons.saveActivity}
                          handleButtonClick={handleButtonClick}
                          handleButtonClickRemove={handleButtonClickRemove}
                          savePartnerStatus={saveActivityStatus}
                          displayTitle={t("searchResultLayout.saveActivity")}
                          company={company}
                        /> */}
                      </Grid>
                    </DialogContent>
                  </Popover>
                  {company?.generalData.websiteName && (
                    <Tooltip
                      title={`${t("searchResultLayout.cardShareTitle")} ${
                        company.generalData.websiteName
                      }`}
                    >
                      <IconButton
                        onClick={() =>
                          handleShare(company.generalData.websiteName || "")
                        }
                      >
                        <ShareOutlinedIcon
                          sx={{ fontSize: "30px", color: "#4889F4" }}
                        />
                      </IconButton>
                    </Tooltip>
                  )}

                  {isFavoriteRemoved ? (
                    <>
                      <IconButton
                        onClick={() => handleButtonClickRemove("favorite")}
                      >
                        <BookmarkIcon
                          sx={{ fontSize: "30px", color: "#FFBD03" }}
                        />
                      </IconButton>
                    </>
                  ) : (
                    <>
                      <IconButton onClick={() => handleButtonClick("favorite")}>
                        <BookmarkBorderOutlinedIcon
                          sx={{ fontSize: "30px", color: "#FFBD03" }}
                        />
                      </IconButton>
                    </>
                  )}
                </>
              ) : (
                <>
                  {company?.socialMedias
                    ?.reduce(
                      (
                        uniqueSocialMedias: CompanySocialMediaModel[],
                        socialMedia: CompanySocialMediaModel
                      ) => {
                        const existingSocialMedia = uniqueSocialMedias.find(
                          (sm) =>
                            sm.typeName.toLowerCase() ===
                            socialMedia.typeName.toLowerCase()
                        );
                        if (!existingSocialMedia) {
                          uniqueSocialMedias.push(socialMedia);
                        }
                        return uniqueSocialMedias;
                      },
                      []
                    )
                    .map((socialMedia, index) => {
                      let socialMediaWithColor = { ...socialMedia, color: "" };
                      let showIcon =
                        socialMediaWithColor.url.includes("https://");

                      switch (socialMedia.typeName.toLowerCase()) {
                        case "facebook":
                          return showIcon ? (
                            <FacebookOutlinedIcon
                              key={index}
                              id="searchResultLayout_icon_facebook"
                              className={classes.facebookIcon}
                              onClick={(event) =>
                                handleSocialMediaClick(
                                  event,
                                  socialMediaWithColor.url
                                )
                              }
                            />
                          ) : null;
                        case "instagram":
                          return showIcon ? (
                            <InstagramIcon
                              key={index}
                              id="searchResultLayout_icon_instagram"
                              className={classes.instagramIcon}
                              onClick={(event) =>
                                handleSocialMediaClick(
                                  event,
                                  socialMediaWithColor.url
                                )
                              }
                            />
                          ) : null;
                        case "twitter":
                          return showIcon ? (
                            <TwitterIcon
                              key={index}
                              id="searchResultLayout_icon_twitter"
                              className={classes.twitterIcon}
                              onClick={(event) =>
                                handleSocialMediaClick(
                                  event,
                                  socialMediaWithColor.url
                                )
                              }
                            />
                          ) : null;
                        case "linkedin":
                          return showIcon ? (
                            <LinkedInIcon
                              key={index}
                              id="searchResultLayout_icon_linkedIn"
                              className={classes.linkedInIcon}
                              onClick={(event) =>
                                handleSocialMediaClick(
                                  event,
                                  socialMediaWithColor.url
                                )
                              }
                            />
                          ) : null;
                        case "youtube":
                          return showIcon ? (
                            <YouTubeIcon
                              key={index}
                              id="searchResultLayout_icon_youtube"
                              className={classes.youtubeIcon}
                              onClick={(event) =>
                                handleSocialMediaClick(
                                  event,
                                  socialMediaWithColor.url
                                )
                              }
                            />
                          ) : null;
                        case "tiktok":
                          return showIcon ? (
                            <FontAwesomeIcon
                              icon={faTiktok}
                              key={index}
                              id="searchResultLayout_icon_tiktok"
                              className={classes.tiktokIcon}
                              onClick={(event) =>
                                handleSocialMediaClick(
                                  event,
                                  socialMediaWithColor.url
                                )
                              }
                            />
                          ) : null;
                        default:
                          return null;
                      }
                    })}
                </>
              )}
            </Grid>
          </Grid>
          <Grid item md={6}></Grid>
          <Grid item md={12}>
            <Grid item md={12} xs={12} className={classes.contentWrapper}>
              <PublicIcon className={classes.icons} />
              <Typography variant="body1" className={classes.label}>
                {t("searchResultLayout.cardAddress")}
              </Typography>
              <Typography
                variant="body1"
                className={classes.contactContent}
                component={"a"}
                href={`https://maps.google.com/maps?q=${encodeURIComponent(
                  `${company?.generalData.place}, ${company?.generalData.street
                    .replace("bb", "")
                    .trim()}`
                )}`}
                target="_blank"
              >
                {`${company?.generalData.place}, ${company?.generalData.street}`}
              </Typography>
            </Grid>
            {company?.websites?.slice(0, 4).map((website, index) => {
              let url = website.url;

              if (
                !website.url.includes("http://") &&
                !website.url.includes("https://")
              ) {
                url = `http://${website.url}`;
              }

              url = url.replace(/^-/, "");

              return (
                <Grid
                  item
                  md={12}
                  xs={12}
                  className={classes.contentWrapper}
                  key={index}
                >
                  <Grid container alignItems="center">
                    <OpenInNewOutlinedIcon className={classes.icons} />
                    <Typography variant="body1" className={classes.label}>
                      {t("searchResultLayout.cardWeb")}:
                    </Typography>
                    <Typography
                      variant="body1"
                      className={classes.contactContent}
                      component={"a"}
                      href={url.startsWith("http") ? url : `http://${url}`}
                      target="_blank"
                    >
                      {url}
                    </Typography>
                  </Grid>
                </Grid>
              );
            })}

            {company?.phones?.slice(0, 4).map((phone, index) => (
              <Grid
                item
                md={12}
                xs={12}
                className={classes.contentWrapper}
                key={index}
              >
                <LocalPhoneOutlinedIcon className={classes.icons} />
                <Typography variant="body1" className={classes.label}>
                  {phone.typeName.charAt(0).toUpperCase() +
                    phone.typeName.slice(1)}
                  :
                </Typography>
                <Typography
                  variant="body1"
                  className={classes.contactContent}
                  component="a"
                  href={`tel:${phone.phoneNumber}`}
                >
                  {phone.phoneNumber}
                </Typography>
              </Grid>
            ))}
            {company?.emails?.slice(0, 4).map((email, index) => (
              <Grid
                item
                md={12}
                xs={12}
                className={classes.contentWrapper}
                key={index}
              >
                <EmailOutlinedIcon className={classes.icons} />
                <Typography variant="body1" className={classes.label}>
                  {email.typeName.charAt(0).toUpperCase() +
                    email.typeName.slice(1)}
                  :
                </Typography>
                <Link href={`mailto:${email.emailAddress}`} underline="none">
                  <Typography
                    id="fullDetailsModal_value_email"
                    variant="body1"
                    className={classes.contactContent}
                    component="a"
                    href={`mailto:${email.emailAddress}`}
                  >
                    {email.emailAddress}
                  </Typography>
                </Link>
              </Grid>
            ))}
          </Grid>
          <Grid item md={12} xs={12}>
            <Typography variant="body1" className={classes.description}>
              {company?.generalData.shortDescription?.slice(0, 500)}
            </Typography>
          </Grid>
          <Grid item md={12} xs={12} mt={1}>
            <AppButton
              label={t("searchResultLayout.cardShowMore")}
              color={colors.primary}
              hover={colors.primary}
              onClick={onClick}
              startIcon={
                fullDetailsStatus === "Pending" ? (
                  <CircularProgress size={"16px"} />
                ) : null
              }
              disabled={fullDetailsStatus === "Pending"}
            />
          </Grid>
          <Grid
            item
            md={12}
            xs={12}
            lg={12}
            mt={1}
            display="flex"
            justifyContent="right"
            alignContent={"center"}
          >
            {company?.categories.map((category, i) => (
              <Grid
                item
                key={i}
                className={classes.activityTagWrapper}
                sx={{ textAlign: "right", margin: "2px" }}
              >
                <Typography
                  variant="body2"
                  sx={{ textAlign: "center", fontSize: "8px" }}
                >
                  {`${category.parentCategoryName}${
                    category.childCategoryNames.length ? " - " : ""
                  }${category.childCategoryNames}`}
                </Typography>
              </Grid>
            ))}
            {/* <Grid item display={"flex"} ml={1}>
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", fontSize: "15px" }}
              >
                {company?.generalData.ratingValue}
              </Typography>
              <StarIcon
                sx={{ color: "#f1b607", cursor: "pointer" }}
                onClick={() => setIsOpenOverallRatingModal(true)}
              />
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
      {/* <OverallRatingModal
        handleClose={() => setIsOpenOverallRatingModal(false)}
        open={isOpenOverallRatingModal}
        company={company || null}
        setSelectedCompany={setSelectedCompany}
        selectedCompany={selectedCompany || null}
      /> */}
    </Card>
  );
}
