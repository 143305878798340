import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

export const Env_UM = {
  Local: "https://localhost:6001",
  Qa: "https://www.poslovniimenik-bih-qa.api.midenas.rs",
  Prod: "https://admin.api.poslovniimenik.net",
};


export const api_um = axios.create({
  baseURL: Env_UM.Prod + "/api",
});
// const api = (
//   method: string,
//   url: string,
//   variables?: any,
//   jwtToken?: string
// ) =>
//   new Promise((resolve, reject) => {
//     axiosInstanceUM({
//       url: url,
//       method,
//       params: method === "get" ? variables : undefined,
//       data: method !== "get" ? variables : undefined,
//     } as AxiosRequestConfig).then(
//       (response) => {
//         resolve(response.data);
//       },
//       (error) => {
//         if (error.response) {
//           if (error.response.data.error) {
//             reject(error.response.data.error);
//           } else {
//             reject(error.response.data);
//           }
//         } else {
//           reject({
//             code: "INTERNAL_ERROR",
//             status: 503,
//             data: {},
//           });
//         }
//       }
//     );
//   });

// export { axiosInstanceUM };
// export default {
//   get: (url: string, variables?: any) => api("get", url, variables),
//   post: (url: string, variables?: any) => api("post", url, variables),
//   put: (url: string, variables?: any) => api("put", url, variables),
//   patch: (url: string, variables?: any) => api("patch", url, variables),
//   delete: (url: string, variables?: any) => api("delete", url, variables),
// };
