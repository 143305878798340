import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import ContactPage from "../../pages/contactPage/contactPage";
import { routes } from "./routes";
import AboutUsPage from "../../pages/aboutUsPage/aboutUsPage";
import HomePage from "../../pages/homePage/homePage";
import EditionsPage from "../../pages/editionsPage/editionsPage";
import AdvertisementRequestPage from "../../pages/advertisementRequestPage/advertisementRequestPage";
import UpdateDataPage from "../../pages/updateDataPage/updateDataPage";
import AdvertisementPage from "../../pages/advertisementPage/advertisementPage";
import DigitalMarketingPage from "../../pages/digitalMarketingPage/digitalMarketingPage";
import ActivitiesPage from "../../pages/activitiesPage/activitiesPage";
import DashboardPage from "../../pages/dashboardPage/dashboardPage";
import MySitePage from "../../pages/mySitePage/mySitePage";
import SupportPage from "../../pages/supportPage/supportPage";
import { useAppSelector } from "../redux/hooks";
import { selectCurrentAccount } from "../../slices/authSlice";
import MySiteViewPage from "../../pages/mySiteViewPage/mySiteViewPage";
import { selectCompanyById } from "../../slices/companySlice";

const AppRouter = () => {
  const companyById = useAppSelector(selectCompanyById);
  const companyWebsiteName = companyById?.generalData.websiteName ?? undefined;
  const currentAccount = useAppSelector(selectCurrentAccount);

  return (
    <Routes>
      <Route path={routes.home} element={<HomePage />} />
      <Route path={routes.contact} element={<ContactPage />} />
      <Route path={routes.about} element={<AboutUsPage />} />
      <Route path={routes.editions} element={<EditionsPage />} />
      <Route
        path={routes.advertisementRequest}
        element={<AdvertisementRequestPage />}
      />
      <Route path={routes.updateData} element={<UpdateDataPage />} />
      <Route path={routes.advertisement} element={<AdvertisementPage />} />
      <Route
        path={routes.digitalMarketing}
        element={<DigitalMarketingPage />}
      />
      {!currentAccount && (
        <Route path={routes.dashboard} element={<Navigate to="/" replace />} />
      )}
      {!currentAccount && (
        <Route path={routes.mySite} element={<Navigate to="/" replace />} />
      )}
      {currentAccount && (
        <Route path={routes.dashboard} element={<DashboardPage />} />
      )}
      <Route path={routes.mySite} element={<MySitePage />} />
      <Route path={routes.support} element={<SupportPage />} />
      <Route path={routes.activities} element={<ActivitiesPage />} />
      <Route path={"/:companyName"} element={<MySiteViewPage />} />
    </Routes>
  );
};

export default AppRouter;
