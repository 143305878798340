import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../app/redux/hooks";
import {
  resetDeletedAccountClientStatus,
  resetDeletedAccountFavoriteStatus,
  resetDeletedAccountPartnerStatus,
  resetDeletedAccountPotentialPartnerStatus,
} from "../../../slices/onboardingSlice";
import { useEffect } from "react";
import { CompanyClientsRequest } from "../../../models/company/companyClientsRequest";
import { toast } from "react-toastify";
import { useDashboardCardModalStyles } from "./dashboardCardModalTableStyles";
import { CompanyEntity } from "../../../models/company/companyEntity";


interface Props {
  rows: CompanyEntity[] | null;
  tableColor: string;
  deletedStatus: string;
  request: CompanyClientsRequest;
  deleteCompany: any;
  getCompanies: any;
  id: string;
}

export default function DashboardCardModalTable({
  rows,
  tableColor,
  deletedStatus,
  request,
  deleteCompany,
  getCompanies,
  id,
}: Props) {
  const dispatch = useAppDispatch();
  const { classes } = useDashboardCardModalStyles();
  const { t } = useTranslation();

  useEffect(() => {
    const resetDeletedStatus = () => {
      let successMessage = "";
      switch (id) {
        case "partners_modal_table":
          dispatch(resetDeletedAccountPartnerStatus());
          break;
        case "potential_partners_modal_table":

          dispatch(resetDeletedAccountPotentialPartnerStatus());
          break;
        case "clients_modal_table":
          dispatch(resetDeletedAccountClientStatus());
          break;
        case "favorites_modal_table":
          dispatch(resetDeletedAccountFavoriteStatus());
          break;
        default:
          break;
      }
      return successMessage;
    };

    if (deletedStatus === "Fulfilled") {
      dispatch(getCompanies(request));
    }
  }, [deletedStatus]);

  return (
    <TableContainer>
      <Table size="small" className={classes.table} id={id}>
        <TableHead id="clients_table_head">
          <TableRow sx={{ color: "#635F5F", fontWeight: "bold" }}>
            <TableCell className={classes.headTableCell}>
              {t("widgetTable.widgetTableName")}
            </TableCell>
            <TableCell className={classes.headTableCell}>
              {t("widgetTable.widgetTableAddress")}
            </TableCell>
            <TableCell className={classes.headTableCell}>
              {t("widgetTable.widgetTableCity")}
            </TableCell>
            <TableCell className={classes.headTableCell}>
              {t("widgetTable.widgetTableCategory")}
            </TableCell>
            <TableCell className={classes.headTableCell} colSpan={2}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {t("widgetTable.widgetTableAction")}
              </div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row, index) => (
            <TableRow
              key={row.name}
              sx={{
                border: "none",
                "&:nth-of-type(odd)": {
                  backgroundColor: index % 2 === 0 ? tableColor : "#FFFFFF",
                },
              }}
            >
              <TableCell
                component="th"
                scope="row"
                className={classes.tableCell}
              >
                {row.name}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {row.streetName}, {row.homeNumber}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {row.placeName}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {row.mainCategories.length > 0 && row.mainCategories[0].name}
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableCell}
                sx={{ padding: 0 }}
              >
                <IconButton
                  id="clients_table_icon_delete"
                  onClick={() => dispatch(deleteCompany(row.id))}
                >
                  <DeleteIcon className={classes.deleteIcon} />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
