import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Button,
  CssBaseline,
  Grid,
  ThemeProvider,
  Typography,
  styled,
} from "@mui/material";
import Footer from "../../features/footer/footer";
import { useLayoutStyles } from "./layoutStyles";
import CookieConsent from "react-cookie-consent";
import { theme } from "./theme";
import AppNotification from "../../components/appNotification/appNotification";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

interface Props {
  children?: React.ReactNode;
}

const StyledCookieConsent = styled(CookieConsent)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background-color: #f8e71c;
  color: #333;
  font-size: 14px;
  border-radius: 4px;
  font

  & > span {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  & button {
    background-color: #333;
    color: #f8e71c;
    border: none;
    padding: 8px 16px;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    fontFamily: Open Sans, sans-serif,

    &:hover {
      background-color: #555;
    }
  }
`;

export default function Layout({ children }: Props) {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { classes } = useLayoutStyles();

  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <CssBaseline />
        <Grid item md={12} xs={12}>
        <AppNotification />
        </Grid>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <StyledCookieConsent buttonText={t("cookieConsent.understand")}>
          <span>
            <Typography variant="body1">{t("cookieConsent.title")}</Typography>
            <Typography variant="body1">
              {t("cookieConsent.description")}
            </Typography>
          </span>
        </StyledCookieConsent>
        <div className={classes.container}>
          <div id="row-layout">{children}</div>
        </div>
        <div className={classes.footerContainer}>
          <Footer />
        </div>
     
       
      </React.Fragment>
    </ThemeProvider>
  );
}
