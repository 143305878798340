import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import CustomOutlinedInput from "../../components/outlinedInput/customOutlinedInput";
import { colors } from "../../app/theme/colors";
import { useMySectionStyles } from "./mySiteSectionStyles";
import { FaYoutube } from "react-icons/fa";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import AppButton from "../../components/appButton/appButton";
import MySiteVideoModel from "../../models/mySite/mySiteVideoModel";
import { useAppDispatch } from "../../app/redux/hooks";
import { saveMySiteVideo } from "../../slices/mySiteSlice";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";

interface Props {
  status: string;
  mySiteVideo: MySiteVideoModel[] | null;
  setMySiteVideo: React.Dispatch<React.SetStateAction<MySiteVideoModel[]>>;
  selectedItems: any;
  cubeType: string;
  handleRemoveVideo: any;
  clickedCube: string;
  selectMySiteVideo: MySiteVideoModel[] | null;
}

const MySiteVideoSection: React.FC<Props> = ({
  cubeType,
  mySiteVideo,
  setMySiteVideo,
  selectedItems,
  handleRemoveVideo,
  clickedCube,
  selectMySiteVideo,
  status
}: Props) => {
  const { classes } = useMySectionStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [buttonText, setButtonText] = useState<string>("");
  const [buttonIcon, setButtonIcon] = useState<any>(null);

  const handleVideoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const link = event.target.value;
    const orderNumber = selectedItems[cubeType]?.orderNumber;

    setMySiteVideo((prevState: MySiteVideoModel[] | null) => {
      if (prevState === null) return [];

      const index = prevState.findIndex(
        (video) => video.orderNumber === orderNumber
      );
      if (index !== -1) {
        const updatedVideos = [...prevState];
        updatedVideos[index] = {
          ...updatedVideos[index],
          link: link,
        };
        return updatedVideos;
      }
      return prevState;
    });
  };

  const handleReset = () => {
    setMySiteVideo((prevState: MySiteVideoModel[] | null) => {
      if (prevState === null) return [];

      return prevState.map((video) =>
        video.orderNumber === selectedItems[cubeType]?.orderNumber
          ? { ...video, link: "" }
          : video
      );
    });
  };

  const handleSave = () => {
    if (mySiteVideo) {
      dispatch(saveMySiteVideo(mySiteVideo));
    }
  };

  useEffect(() => {
    if (mySiteVideo) {
      const photoToUpdate = mySiteVideo.find(
        (video) => video.orderNumber === selectedItems[cubeType]?.orderNumber
      );
      if (photoToUpdate) {
        const hasId =
          photoToUpdate.id !== "00000000-0000-0000-0000-000000000000";
        if (hasId) {
          setButtonText(t("buttons.edit") as string);
          setButtonIcon(<EditIcon />);
        } else {
          setButtonText(t("buttons.save") as string);
          setButtonIcon(<CheckCircleOutlineIcon />);
        }
      }
    }
  }, [mySiteVideo, cubeType, clickedCube, selectMySiteVideo, t]);

  return (
    <Grid
      container
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      mt={1}
      padding="10px"
    >
      <Grid
        item
        md={12}
        justifyContent="flex-start"
        alignItems="center"
        display="flex"
      >
        <Grid item>
          <VideoCallIcon sx={{ color: "rgba(75, 138, 244, 0.8)" }} />
        </Grid>
        <Grid item>
          <Typography variant="body2" sx={{ marginLeft: "5px" }}>
            {t("mySite.mySiteVideoTitle")}
          </Typography>
        </Grid>
      </Grid>
      <Grid item md={12} display={"flex"} alignItems={"center"} mt={2}>
        <Grid item>
          <Box mr={1}>
            <FaYoutube size={30} style={{ color: "rgba(255, 0, 0, 1)" }} />
          </Box>
        </Grid>
        <Grid item style={{ flexGrow: 1 }}>
          <CustomOutlinedInput
            width="100%"
            labelColor={colors.primary}
            value={
              mySiteVideo
                ? mySiteVideo
                    .filter(
                      (section) =>
                        section.orderNumber ===
                        selectedItems[cubeType]?.orderNumber
                    )
                    .map((section) => section.link)
                    .join("")
                : ""
            }
            onChange={handleVideoChange}
          />
        </Grid>
      </Grid>
      <Grid item md={12} display={"flex"} justifyContent={"flex-end"} mt={1}>
        <Grid item mr={1}>
          <AppButton
            label={t("buttons.delete")}
            color={colors.red}
            hover={colors.red}
            startIcon={<DeleteIcon />}
            onClick={handleRemoveVideo}
          />
        </Grid>
        <Grid item>
          <AppButton
            label={buttonText}
            color={colors.green}
            hover={colors.green}
            onClick={handleSave}
            startIcon={buttonIcon}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MySiteVideoSection;
