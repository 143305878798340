import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { colors } from "../../../app/theme/colors";
import AppButton from "../../../components/appButton/appButton";
import { useAppDispatch, useAppSelector } from "../../../app/redux/hooks";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import logo from "../../../images/logoFull.png";
import CustomOutlinedInput from "../../../components/outlinedInput/customOutlinedInput";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useSetPasswordModalStyles } from "./setPasswordModalStyles";
import SetPasswordModel from "../../../models/auth/setPasswordModel";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import RemoveCircleOutlineTwoToneIcon from "@mui/icons-material/RemoveCircleOutlineTwoTone";
import { LoginRequest } from "../../../models/auth/loginRequest";
import {
  resetPassword,
  resetResetPasswordStatus,
  selectResetPasswordStatus,
} from "../../../slices/authSlice";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../app/router/routes";

interface Props {
  open: any;
  handleClose: any;
  currentAccount: any;
  login: LoginRequest;
  setIsOpenSetPasswordModal: any;
  closeLoginModal: any;
}

interface PasswordValidation {
  greaterThanOrEqualTo8: boolean;
  atLeastOneUpperCase: boolean;
  atLeastOneNumber: boolean;
  atLeastOneSpecialCharacter: boolean;
  passwordConfirm: boolean;
}
export default function SetPasswordModal({
  handleClose,
  open,
  currentAccount,
  login,
  closeLoginModal,
  setIsOpenSetPasswordModal,
}: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { classes } = useSetPasswordModalStyles();
  const theme = useTheme();
  const navigate = useNavigate();

  const resetPasswordStatus = useAppSelector(selectResetPasswordStatus);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const INIT_SET_PASSWORD: SetPasswordModel = {
    email: "",
    newPassword: "",
    confirmNewPassword: "",
    oldPassword: "",
  };

  const [setPassword, setSetPassword] = useState<SetPasswordModel>({
    ...INIT_SET_PASSWORD,
  });

  const passwordValidation: PasswordValidation = {
    greaterThanOrEqualTo8: setPassword.newPassword.length >= 8,
    atLeastOneUpperCase: /[A-Z]/.test(setPassword.newPassword),
    atLeastOneNumber: /\d/.test(setPassword.newPassword),
    atLeastOneSpecialCharacter: /[!@#$%^&*(),.?":{}|<>]/.test(
      setPassword.newPassword
    ),
    passwordConfirm:
      setPassword.newPassword !== "" &&
      setPassword.confirmNewPassword !== "" &&
      setPassword.newPassword === setPassword.confirmNewPassword,
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setSetPassword((prevState) => ({
      ...prevState,
      [id === "setPasswordModal-input-newPassword"
        ? "newPassword"
        : "confirmNewPassword"]: value,
    }));
    setPasswordError(false);
  };

  const handleLogin = () => {
    dispatch(resetPassword(setPassword));
  };

  const handleDialogClose = () => {
    setEmailError(false);
    setPasswordError(false);

    handleClose();
  };

  useEffect(() => {
    if (currentAccount) {
      setSetPassword((prevState) => ({
        ...prevState,
        email: currentAccount.email,
        oldPassword: login.password,
      }));
    }
  }, [currentAccount]);

  const handleHomePage = () => {
    navigate(routes.home);
  };

  useEffect(() => {
    if (resetPasswordStatus === "Fulfilled") {
      handleHomePage();
      handleDialogClose();
      closeLoginModal();
      dispatch(resetResetPasswordStatus());
    }
  }, [resetPasswordStatus]);

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      fullWidth
      maxWidth="xs"
      id="setPasswordModal-dialog"
      PaperProps={{ sx: { borderRadius: "15px", overflow: "hidden" } }}
    >
      <IconButton
        id="setPasswordModal-iconButton-close"
        edge="end"
        color="inherit"
        onClick={handleDialogClose}
        aria-label="close"
        sx={{
          position: "absolute",
          top: theme.spacing(1),
          right: theme.spacing(2),
        }}
        className={classes.closeIcon}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent className={classes.dialogContent}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          padding={"5px"}
          sx={{ textAlign: "center" }}
        >
          <Grid item md={12} xs={12}>
            <img
              src={logo}
              alt="Logo"
              className={classes.logoImage}
              id="setPasswordModal-img-logo"
            />
          </Grid>
          <Grid item md={12} xs={12} mt={5}>
            <CustomOutlinedInput
              id="setPasswordModal-input-newPassword"
              type={isNewPasswordVisible ? "text" : "password"}
              label={t("changePassword.newPassword")}
              width="100%"
              labelColor={colors.primary}
              onChange={handlePasswordChange}
              value={setPassword.newPassword}
              error={emailError}
              errorMessage={t("loginModal.loginErrorEmail")}
              endAdornment={
                isNewPasswordVisible ? (
                  <IconButton
                    onClick={() => setIsNewPasswordVisible(false)}
                    sx={{ color: colors.primary }}
                  >
                    <VisibilityIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => setIsNewPasswordVisible(true)}
                    sx={{ color: colors.primary }}
                  >
                    <VisibilityOffIcon />
                  </IconButton>
                )
              }
            />
          </Grid>
          <Grid item md={12} xs={12} mt={3}>
            <CustomOutlinedInput
              type={isPasswordVisible ? "text" : "password"}
              id="setPasswordModal-input-confirmPassword"
              label={t("changePassword.confirmPassword")}
              width="100%"
              labelColor={colors.primary}
              onChange={handlePasswordChange}
              value={setPassword.confirmNewPassword}
              error={passwordError}
              endAdornment={
                isPasswordVisible ? (
                  <IconButton
                    sx={{ color: colors.primary }}
                    onClick={() => setIsPasswordVisible(false)}
                  >
                    <VisibilityIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    sx={{ color: colors.primary }}
                    onClick={() => setIsPasswordVisible(true)}
                  >
                    <VisibilityOffIcon />
                  </IconButton>
                )
              }
              errorMessage={t("loginModal.loginErrorPassword")}
            />
          </Grid>
          <Box my={2}>
            {
              <Typography
                variant="body1"
                sx={{
                  color: passwordValidation.greaterThanOrEqualTo8
                    ? colors.green
                    : colors.validationErrorMessage,
                  display: "flex",
                  my: 1,
                }}
              >
                {passwordValidation.greaterThanOrEqualTo8 ? (
                  <CheckCircleTwoToneIcon sx={{ mr: 1 }} />
                ) : (
                  <RemoveCircleOutlineTwoToneIcon sx={{ mr: 1 }} />
                )}
                {t("setPasswordModal.setPasswordMinimumEightCharacter")}
              </Typography>
            }

            {
              <Typography
                variant="body1"
                sx={{
                  color: passwordValidation.atLeastOneUpperCase
                    ? colors.green
                    : colors.validationErrorMessage,
                  display: "flex",
                  my: 1,
                }}
              >
                {passwordValidation.atLeastOneUpperCase ? (
                  <CheckCircleTwoToneIcon sx={{ mr: 1 }} />
                ) : (
                  <RemoveCircleOutlineTwoToneIcon sx={{ mr: 1 }} />
                )}
                {t("setPasswordModal.setPasswordCapitalLetter")}
              </Typography>
            }

            {
              <Typography
                variant="body1"
                sx={{
                  color: passwordValidation.atLeastOneNumber
                    ? colors.green
                    : colors.validationErrorMessage,
                  display: "flex",
                  my: 1,
                }}
              >
                {passwordValidation.atLeastOneNumber ? (
                  <CheckCircleTwoToneIcon sx={{ mr: 1 }} />
                ) : (
                  <RemoveCircleOutlineTwoToneIcon sx={{ mr: 1 }} />
                )}
                {t("setPasswordModal.setPasswordNumber")}
              </Typography>
            }

            {
              <Typography
                variant="body1"
                sx={{
                  color: passwordValidation.atLeastOneSpecialCharacter
                    ? colors.green
                    : colors.validationErrorMessage,
                  display: "flex",
                  my: 1,
                }}
              >
                {passwordValidation.atLeastOneSpecialCharacter ? (
                  <CheckCircleTwoToneIcon sx={{ mr: 1 }} />
                ) : (
                  <RemoveCircleOutlineTwoToneIcon sx={{ mr: 1 }} />
                )}
                {t("setPasswordModal.setPasswordSpecialCharacter")}
              </Typography>
            }

            {
              <Typography
                variant="body1"
                sx={{
                  color: passwordValidation.passwordConfirm
                    ? colors.green
                    : colors.validationErrorMessage,
                  display: "flex",
                  my: 1,
                }}
              >
                {passwordValidation.passwordConfirm ? (
                  <CheckCircleTwoToneIcon sx={{ mr: 1 }} />
                ) : (
                  <RemoveCircleOutlineTwoToneIcon sx={{ mr: 1 }} />
                )}
                {t("setPasswordModal.setPasswordConfirmPassword")}
              </Typography>
            }
          </Box>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <AppButton
          id="fullDetailsModal_button_close"
          onClick={handleLogin}
          color={colors.primary}
          hover={colors.primary}
          label={t("buttons.login")}
          width="100%"
          disabled={
            !(
              passwordValidation.atLeastOneNumber &&
              passwordValidation.atLeastOneSpecialCharacter &&
              passwordValidation.atLeastOneUpperCase &&
              passwordValidation.greaterThanOrEqualTo8 &&
              passwordValidation.passwordConfirm
            )
          }
        />
      </DialogActions>
    </Dialog>
  );
}
