import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  IconButton,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomOutlinedInput from "../../components/outlinedInput/customOutlinedInput";
import { colors } from "../../app/theme/colors";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import MySitePhoneRequest from "../../models/mySite/mySitePhoneModel";
import { useAppDispatch } from "../../app/redux/hooks";
import { saveMySitePhone } from "../../slices/mySiteSlice";
import AppButton from "../../components/appButton/appButton";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PhoneModel from "../../models/mySite/phoneModel";
import MySitePhoneModel from "../../models/mySite/mySitePhoneModel";
import { useTranslation } from "react-i18next";
import { ApiStatus } from "../../models/app/apiStatus";

interface Props {
  status: string;
  setPhoneSections: React.Dispatch<React.SetStateAction<MySitePhoneRequest[]>>;
  phoneSections: MySitePhoneRequest[];
  handleRemovePhone: any;
  clickedCube: string;
  selectMySitePhone: MySitePhoneModel[] | null;
  selectedItems: any;
  cubeType: string;
  setMySitePhoneSection: React.Dispatch<
    React.SetStateAction<MySitePhoneRequest[]>
  >;
}

const MySitePhoneSection: React.FC<Props> = ({
  phoneSections,
  setPhoneSections,
  clickedCube,
  handleRemovePhone,
  selectMySitePhone,
  selectedItems,
  cubeType,
  setMySitePhoneSection,
  status,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [buttonText, setButtonText] = useState<string>("");
  const [buttonIcon, setButtonIcon] = useState<any>(null);
  useEffect(() => {
    if (phoneSections) {
      const photoToUpdate = phoneSections.find(
        (photo) => photo.orderNumber === selectedItems[cubeType]?.orderNumber
      );
      if (photoToUpdate) {
        const hasId =
          photoToUpdate.id !== "00000000-0000-0000-0000-000000000000";
        if (hasId) {
          setButtonText(t("buttons.edit") as string);
          setButtonIcon(<EditIcon />);
        } else {
          setButtonText(t("buttons.save") as string);
          setButtonIcon(<CheckCircleOutlineIcon />);
        }
      }
    }
  }, [phoneSections, cubeType, clickedCube, selectMySitePhone, t]);

  if (!phoneSections || phoneSections.length === 0) {
    return null;
  }

  const handleAddPhone = () => {
    setPhoneSections((prevSections: MySitePhoneRequest[]) => {
      const updatedSections = prevSections.map((section) => {
        if (section.orderNumber === selectedItems[cubeType]?.orderNumber) {
          return {
            ...section,
            phones: [...section.phones, { name: "", phoneNumber: "" }],
          };
        }
        return section;
      });
      return updatedSections;
    });
  };

  const handleDeletePhone = (sectionIndex: number, phoneIndex: number) => {
    setPhoneSections((prevSections: MySitePhoneRequest[]) => {
      const updatedSections = prevSections.map((section, idx) => {
        if (section.orderNumber === selectedItems[cubeType]?.orderNumber) {
          const updatedPhones = section.phones.filter(
            (_, idx2) => idx2 !== phoneIndex
          );
          return {
            ...section,
            phones: updatedPhones,
          };
        }
        return section;
      });

      return updatedSections;
    });
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    sectionIndex: number,
    phoneIndex: number,
    field: string
  ) => {
    const { value } = e.target;
    setPhoneSections((prevSections: MySitePhoneRequest[]) => {
      const updatedSections = prevSections.map((section, idx) => {
        if (section.orderNumber === selectedItems[cubeType]?.orderNumber) {
          const updatedPhones = section.phones.map((phone, idx2) => {
            if (idx2 === phoneIndex) {
              return {
                ...phone,
                [field]: value,
              };
            }
            return phone;
          });
          return {
            ...section,
            phones: updatedPhones,
          };
        }
        return section;
      });
      return updatedSections;
    });
  };

  const handleSave = () => {
    dispatch(saveMySitePhone(phoneSections));
  };

  return (
    <Grid
      container
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      mt={1}
      padding="10px"
    >
      <Grid
        item
        md={12}
        justifyContent="flex-start"
        alignItems="center"
        display="flex"
      >
        <Grid item>
          <AddIcCallIcon sx={{ color: "rgba(75, 138, 244, 0.8)" }} />
        </Grid>
        <Grid item>
          <Typography variant="body2" sx={{ marginLeft: "5px" }}>
            {t("mySite.mySitePhoneTitle")}
          </Typography>
        </Grid>
      </Grid>
      {phoneSections
        .filter(
          (section) =>
            section.orderNumber === selectedItems[cubeType]?.orderNumber
        )
        .map((section, sectionIndex) => (
          <React.Fragment key={sectionIndex}>
            {section.phones.map((phone, index) => (
              <Grid
                key={`${sectionIndex}-${index}`}
                item
                md={12}
                display="flex"
                alignItems="center"
                mt={2}
              >
                <Box mb={1} ml={1}>
                  <CustomOutlinedInput
                    labelColor={colors.primary}
                    label="Naziv broja telefona:"
                    name="name"
                    value={phone.name}
                    onChange={(e) =>
                      handleInputChange(e, sectionIndex, index, "name")
                    }
                  />
                </Box>
                <Box mb={1} ml={1}>
                  <CustomOutlinedInput
                    labelColor={colors.primary}
                    name="phoneNumber"
                    label="Telefon:"
                    value={phone.phoneNumber}
                    onChange={(e) =>
                      handleInputChange(e, sectionIndex, index, "phoneNumber")
                    }
                  />
                </Box>
                {section.phones.length > 1 && (
                  <Tooltip title={t("mySite.mySitePhoteTooltipDelete")}>
                    <IconButton
                      onClick={() => handleDeletePhone(sectionIndex, index)}
                    >
                      <DeleteIcon sx={{ color: colors.red }} />
                    </IconButton>
                  </Tooltip>
                )}

                {index === section.phones.length - 1 && (
                  <Tooltip title={t("mySite.mySitePhoneTooltipAdd")}>
                    <IconButton onClick={() => handleAddPhone()}>
                      <AddIcon sx={{ color: colors.primary }} />
                    </IconButton>
                  </Tooltip>
                )}
              </Grid>
            ))}
          </React.Fragment>
        ))}

      <Grid
        container
        justifyContent={"flex-end"}
        alignItems={"center"}
        spacing={1}
        mt={5}
      >
        <Grid item>
          <AppButton
            startIcon={<EditIcon />}
            label={t("buttons.delete")}
            color={colors.red}
            hover={colors.red}
            onClick={handleRemovePhone}
          />
        </Grid>
        <Grid item>
          <AppButton
            startIcon={
              status === ApiStatus.Pending ? (
                <CircularProgress size={15} sx={{ color: colors.primary }} />
              ) : (
                buttonIcon
              )
            }
            disabled={status === ApiStatus.Pending}
            label={buttonText}
            color={colors.green}
            hover={colors.green}
            onClick={handleSave}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MySitePhoneSection;
