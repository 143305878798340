import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

export const Env_Poslovni_Imenik = {
  Local: "https://localhost:5001",
  Qa: "https://www.poslovniimenik-bih-qa.api.midenas.rs",
  Prod: "https://admin.api.poslovniimenik.net",
};




export const api_poslovni_imenik = axios.create({
  baseURL: Env_Poslovni_Imenik.Prod + "/api",
});

// const getBaseURL = () => {
//   return Env_Poslovni_Imenik.Local;
// };

// const axiosInstance: AxiosInstance = axios.create({
//   baseURL: getBaseURL() + "/api",
//   headers: {
//     Authorization: localStorage.getItem("accessToken")
//       ? "Bearer " + localStorage.getItem("accessToken")
//       : NaN,
//   },
// });

// const api_poslovni_imenik = (
//   method: string,
//   url: string,
//   variables?: any,
//   jwtToken?: string
// ) =>
//   new Promise((resolve, reject) => {
//     axios({
//       baseURL: getBaseURL() + "/api",
//       url: url,
//       method,
//       params: method === "get" ? variables : undefined,
//       data: method !== "get" ? variables : undefined,
//       headers: {
//         Authorization: localStorage.getItem("accessToken")
//           ? "Bearer " + localStorage.getItem("accessToken")
//           : NaN,
//       },
//     } as AxiosRequestConfig).then(
//       (response) => {
//         resolve(response.data);
//       },
//       (error) => {
//         if (error.response) {
//           if (error.response.data.error) {
//             reject(error.response.data.error);
//           } else {
//             reject(error.response.data);
//           }
//         } else {
//           reject({
//             code: "INTERNAL_ERROR",
//             status: 503,
//             data: {},
//           });
//         }
//       }
//     );
//   });

// export { axiosInstance };
// export default {
//   get: (url: string, variables?: any) => api_poslovni_imenik("get", url, variables),
//   post: (url: string, variables?: any) => api_poslovni_imenik("post", url, variables),
//   put: (url: string, variables?: any) => api_poslovni_imenik("put", url, variables),
//   patch: (url: string, variables?: any) => api_poslovni_imenik("patch", url, variables),
//   delete: (url: string, variables?: any) => api_poslovni_imenik("delete", url, variables),
// };
