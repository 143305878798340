import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Env_UM } from "../../app/redux/api_um";

interface Props {
  images: any;
}

const Gallery = ({ images }: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const convertedImages = images?.map((image: any) => ({
    src: image?.photoBase64,
  }));

  return (
    <>
      {isDesktop && (
        <Carousel
          showArrows={true}
          showThumbs={true}
          showStatus={false}
          autoPlay={true}
          interval={5000}
          infiniteLoop={true}
          stopOnHover={true}
          useKeyboardArrows={true}
        >
          {convertedImages?.map((image: any, index: any) => (
            <div key={index} style={{ textAlign: "center", width: "100%" }}>
              <img
                src={Env_UM.Prod + "/" + image.src}
                alt={`gallery_${index}`}
                style={{ maxWidth: "100%", maxHeight: "auto" }}
              />
            </div>
          ))}
        </Carousel>
      )}

      {isMobile && (
        <Carousel
          showArrows={true}
          showThumbs={true}
          showStatus={false}
          autoPlay={true}
          interval={5000}
          infiniteLoop={true}
          stopOnHover={true}
          useKeyboardArrows={true}
        >
          {convertedImages?.map((image: any, index: any) => (
            <div key={index} style={{ textAlign: "center", width: "100%" }}>
              <Grid container justifyContent="center">
                <Grid item xs={12} sm={8} md={6} lg={4}>
                  <img
                    src={Env_UM.Prod + "/" + image.src}
                    alt={`Image_gallery ${index}`}
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                </Grid>
              </Grid>
            </div>
          ))}
        </Carousel>
      )}
    </>
  );
};

export default Gallery;
