import { CircularProgress, Grid, Typography } from "@mui/material";
import AppButton from "../../components/appButton/appButton";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import { colors } from "../../app/theme/colors";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import MySitePhotoRequest from "../../models/mySite/mySitePhotoModel";
import { useAppDispatch } from "../../app/redux/hooks";
import { saveMySitePhoto } from "../../slices/mySiteSlice";
import MySitePhotoModel from "../../models/mySite/mySitePhotoModel";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ApiStatus } from "../../models/app/apiStatus";
import ImageIcon from '@mui/icons-material/Image';
interface Props {
  selectedPhoto: MySitePhotoRequest[] | null;
  isOpenUploadImgModal: boolean;
  setIsOpenUploadImgModal: React.Dispatch<React.SetStateAction<boolean>>;
  clickedCube: string;
  cubeType: string;
  selectedItems: any;
  selectMySiteImg: MySitePhotoModel[] | null;
  handleRemoveImg: any;
  status: string;
}

export default function MySiteImgSection({
  selectedPhoto,
  isOpenUploadImgModal,
  clickedCube,
  cubeType,
  selectedItems,
  selectMySiteImg,
  handleRemoveImg,
  status,
  setIsOpenUploadImgModal
}: Props) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [buttonText, setButtonText] = useState<string>("");
  const [buttonIcon, setButtonIcon] = useState<any>(null);

  const handleSave = () => {
    if (selectedPhoto) {
      dispatch(saveMySitePhoto(selectedPhoto));
    }
  };

  useEffect(() => {
    if (selectedPhoto) {
      const photoToUpdate = selectedPhoto.find(
        (photo) => photo.orderNumber === selectedItems[cubeType]?.orderNumber
      );
      if (photoToUpdate) {
        const hasId =
          photoToUpdate.id !== "00000000-0000-0000-0000-000000000000";
        if (hasId) {
          setButtonText(t("buttons.edit") as string);
          setButtonIcon(<EditIcon />);
        } else {
          setButtonText(t("buttons.save") as string);
          setButtonIcon(<CheckCircleOutlineIcon />);
        }
      }
    }
  }, [selectedPhoto, clickedCube, cubeType, selectMySiteImg, t]);

  return (
    <>
      <Grid
        container
        display={"flex"}
        justifyContent={"flex-start"}
        alignItems={"center"}
        mt={1}
        padding={"10px"}
      >
        {selectedPhoto && (
          <>
            <Grid
              item
              md={12}
              xs={12}
              justifyContent={"flex-start"}
              alignItems={"center"}
              display={"flex"}
            >
              <Grid item>
                <AddAPhotoIcon sx={{ color: "rgba(75, 138, 244, 0.8)" }} />
              </Grid>
              <Grid item>
                <Typography variant="body2" sx={{ marginLeft: "5px" }}>
                  {t("mySite.mySiteImgTitle")}
                </Typography>
              </Grid>
            </Grid>
            {!isOpenUploadImgModal && (
              <>
                <Grid
                  item
                  md={12}
                  sx={{
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  {selectedPhoto && (
                    <>
                      <Grid item xs={12} md={12} style={{ textAlign: "left" }}>
                        <img
                          src={
                            selectedPhoto.find(
                              (photo) =>
                                photo.orderNumber ===
                                selectedItems[cubeType]?.orderNumber
                            )?.base64Photo || ""
                          }
                          alt="uploaded"
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        />
                      </Grid>
                      <Grid item md={12} mt={1}>
                      <AppButton label={"Promeni fotografiju"} color={colors.primary} hover={colors.primary} onClick={() => setIsOpenUploadImgModal(true)} startIcon={<ImageIcon />}/>

                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{ textAlign: "right" }}
                        display={"flex"}
                        justifyContent={"flex-end"}
                        mt={1}
                      >
                        <Grid item mr={1}>
                          <AppButton
                            startIcon={<DeleteIcon />}
                            label={t("buttons.delete")}
                            color={colors.red}
                            hover={colors.red}
                            onClick={handleRemoveImg}
                          />
                        </Grid>
                        <AppButton
                          startIcon={
                            status === ApiStatus.Pending ? (
                              <CircularProgress
                                size={15}
                                sx={{ color: colors.primary }}
                              />
                            ) : (
                              buttonIcon
                            )
                          }
                          disabled={status === ApiStatus.Pending}
                          label={buttonText}
                          color={colors.green}
                          hover={colors.green}
                          onClick={handleSave}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
    </>
  );
}
