import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Box, CircularProgress, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import AppButton from "../../components/appButton/appButton";
import EditIcon from "@mui/icons-material/Edit";
import { colors } from "../../app/theme/colors";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import "./mySiteTextSection.css";
import { useAppDispatch } from "../../app/redux/hooks";
import { saveMySiteText } from "../../slices/mySiteSlice";
import MySiteTextModel from "../../models/mySite/mySiteTextModel";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import { ApiStatus } from "../../models/app/apiStatus";

interface Props {
  mySiteTextSections: MySiteTextModel[];
  setMySiteTextSections: React.Dispatch<
    React.SetStateAction<MySiteTextModel[]>
  >;
  selectedItems: any;
  cubeType: string;
  handleRemoveText: any;
  selectMySiteText: MySiteTextModel[] | null;
  clickedCube: string;
  status: string;
}

const MySiteTextSection: React.FC<Props> = ({
  mySiteTextSections,
  setMySiteTextSections,
  selectedItems,
  cubeType,
  handleRemoveText,
  selectMySiteText,
  clickedCube,
  status,
}: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const theme = useTheme()

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [buttonText, setButtonText] = useState<string>("");
  const [buttonIcon, setButtonIcon] = useState<any>(null);
  const handleTextChange = (text: string, orderNumber: number) => {
    setMySiteTextSections((prevState: MySiteTextModel[]) => {
      const index = prevState.findIndex(
        (section) =>
          section.orderNumber === selectedItems[cubeType]?.orderNumber
      );
      if (index !== -1) {
        const updatedSections = [...prevState];
        updatedSections[index] = {
          ...updatedSections[index],
          value: text,
        };
        return updatedSections;
      }
      return prevState;
    });
  };

  const handleSave = () => {
    dispatch(saveMySiteText(mySiteTextSections));
  };

  useEffect(() => {
    if (mySiteTextSections) {
      const photoToUpdate = mySiteTextSections.find(
        (photo) => photo.orderNumber === selectedItems[cubeType]?.orderNumber
      );
      if (photoToUpdate) {
        const hasId =
          photoToUpdate.id !== "00000000-0000-0000-0000-000000000000";
        if (hasId) {
          setButtonText(t("buttons.edit") as string);
          setButtonIcon(<EditIcon />);
        } else {
          setButtonText(t("buttons.save") as string);
          setButtonIcon(<CheckCircleOutlineIcon />);
        }
      }
    }
  }, [mySiteTextSections, cubeType, clickedCube, selectMySiteText, t]);

  return (
    <Grid
      container
      display={"flex"}
      justifyContent={"flex-start"}
      alignItems={"center"}
      mt={1}
      padding={"10px"}
    >
      <Grid
        item
        md={12}
        justifyContent={"flex-start"}
        alignItems={"center"}
        display={"flex"}
      >
        <Grid item>
          <TextFieldsIcon sx={{ color: "rgba(75, 138, 244, 0.8)" }} />
        </Grid>
        <Grid item>
          <Typography variant="body2" sx={{ marginLeft: "5px" }}>
            {t("mySite.mySiteTextTitle")}
          </Typography>
        </Grid>
      </Grid>
      <Grid item md={12} mb={1} style={{ position: "relative" }}>
        <ReactQuill
          className="custom-react-quill"
          theme="snow"
          modules={{
            toolbar: [
              [{ header: [1, 2, false] }],
              ["bold", "italic", "underline", "strike"],
              [{ align: [] }],
              [{ list: "ordered" }, { list: "bullet" }],
              ["clean"],
            ],
          }}
          formats={[
            "header",
            "bold",
            "italic",
            "underline",
            "strike",
            "align",
            "list",
            "bullet",
            "clean",
          ]}
          value={mySiteTextSections
            .filter(
              (section) =>
                section.orderNumber === selectedItems[cubeType]?.orderNumber
            )
            .map((section) => section.value)
            .join("")}
          onChange={(text) =>
            handleTextChange(text, selectedItems[cubeType]?.orderNumber)
          }
        />
        <Grid
          container
          justifyContent={"flex-end"}
          alignItems={"center"}
          spacing={1}
          mt={isMobile ? 8 : 5}
        >
          <Grid item>
            <AppButton
              startIcon={<DeleteIcon />}
              label={t("buttons.delete")}
              color={colors.red}
              hover={colors.red}
              onClick={handleRemoveText}
            />
          </Grid>
          <Grid item>
            <AppButton
              startIcon={
                status === ApiStatus.Pending ? (
                  <CircularProgress size={15} sx={{ color: colors.primary }} />
                ) : (
                  buttonIcon
                )
              }
              disabled={status === ApiStatus.Pending}
              label={buttonText}
              color={colors.green}
              hover={colors.green}
              onClick={handleSave}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default MySiteTextSection;
