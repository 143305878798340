import React from "react";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { useEditionsSectionCardStyles } from "./editionsSectionCardStyles";
import AppButton from "../../components/appButton/appButton";
import { colors } from "../../app/theme/colors";
import DownloadIcon from "@mui/icons-material/Download";
import { useState } from "react";
import { useEditionsData } from "../../mockData/mockData";
import { useTranslation } from "react-i18next";
import { routes } from "../../app/router/routes";

export default function EditionsSectionCard() {
  const { t } = useTranslation();
  const { classes } = useEditionsSectionCardStyles();
  const editions = useEditionsData();

  const [isDownloading, setIsDownloading] = useState(false);

  function handleDownloadPdf() {
    window.open("https://poslovniimenik.net/catalog/katalog_2024.pdf");
  }
  return (
    <>
      {editions.map((edition, index) => (
        <Grid mt={index <= 0 ? 0 : 2} key={index}>
          <Card
            className={classes.card}
            key={index}
            id={`editionsSectionCard_card_${index}`}
          >
            <Box className={classes.box}>
              <CardContent>
                <Grid container>
                  <Grid item md={6} xs={12}>
                    <Grid item md={12} xs={12}>
                      <Typography variant={"h4"} className={classes.title}>
                        {edition.title}
                      </Typography>
                    </Grid>
                    <Grid item md={12} xs={12} mt={2}>
                      <Typography variant={"body1"}>
                        {edition.textFirst}
                      </Typography>
                    </Grid>
                    <Grid item md={12} xs={12} mt={2}>
                      <Typography variant={"body1"}>{edition.text}</Typography>
                    </Grid>
                    <Grid item md={12} xs={12} mt={2}>
                      <Typography variant={"body1"}>{edition.text2}</Typography>
                    </Grid>
                    <Grid item md={12} xs={12} mt={2}>
                      <Typography variant={"body1"}>
                        {edition?.text3.split("- ").map((item, index) => (
                          <React.Fragment key={index}>
                            {index > 0 && <br />}
                            {`${index === 0 ? "" : "- "}${item}`}
                          </React.Fragment>
                        ))}
                      </Typography>
                    </Grid>
                    <Grid item md={12} xs={12} mt={2}>
                      <Typography variant={"body1"}>{edition.text4}</Typography>
                    </Grid>
                    {edition.text5 !== "" && (
                      <Grid item md={12} xs={12} mt={2}>
                        <Typography variant={"body1"}>
                          {edition.text5}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Grid
                      container
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      {edition.logo !== "" && (
                        <a
                          href={routes.home}
                          target="_blank"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            onClick={() => {
                              setIsDownloading(true);
                              handleDownloadPdf();
                              setIsDownloading(false);
                            }}
                            src={edition?.logo}
                            alt="catalog"
                            className={classes.catalog}
                            style={{
                              width: edition?.width,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          />
                        </a>
                      )}
                      {edition.hasButton !== false && (
                        <Grid
                          item
                          md={12}
                          xs={12}
                          mt={1}
                          justifyContent="center"
                          alignItems="center"
                          textAlign={"center"}
                        >
                          <AppButton
                            color={colors.green}
                            hover={colors.green}
                            label={
                              isDownloading
                                ? t("buttons.downloading")
                                : t("buttons.download")
                            }
                            borderRadius={"20px"}
                            startIcon={<DownloadIcon />}
                            onClick={() => {
                              setIsDownloading(true);
                              handleDownloadPdf();
                              setIsDownloading(false);
                            }}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Box>
          </Card>
        </Grid>
      ))}
    </>
  );
}
