import {
  AppBar,
  Toolbar,
  Button,
  Grid,
  Grow,
  Autocomplete,
  TextField,
  MenuList,
  MenuItem,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import Typography from "@mui/material/Typography";
import backgroundImg from "../../../images/ba-03.png";
import logoFull from "../../../images/logoFull.png";
import { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../app/router/routes";
import AppButton from "../../../components/appButton/appButton";
import { colors } from "../../../app/theme/colors";
import Popover from "@mui/material/Popover";
import { useMainHeaderStyles } from "./mainHeaderStyles";
import { useAppDispatch, useAppSelector } from "../../../app/redux/hooks";
import { getAllPlaces, selectAllPlaces } from "../../../slices/addressSlice";
import { useTranslation } from "react-i18next";
import {
  getAllCategories,
  getCompaniesAuthorized,
  getCompaniesUnauthorized,
  resetCompany,
  resetSearch,
  selectSearchStatus,
} from "../../../slices/companySlice";
import { AdvanceSearchFilterModel } from "../../../models/advanceSearchFilterModel";
import SelectCategories from "../../../components/selectCategories/selectCategories";
import useGetAllCategoryShortDetails from "../../../hook/categories/useGetAllCategoryShortDetails";
import { CategoryShortDetail } from "../../../models/company/categoryShortDetail";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import LanguageSwitcher from "../../../components/languageSwitcher/languageSwitcher";
import CustomOutlinedInput from "../../../components/outlinedInput/customOutlinedInput";
import CloseIcon from "@mui/icons-material/Close";
import { INIT_ADVANCE_SEARCH } from "../../../models/init_data/initData";
import { selectCurrentAccount } from "../../../slices/authSlice";

interface Props {
  advanceSearchGlobal: any;
  setAdvanceSearchGlobal: any;
  advanceSearch: any;
  setAdvanceSearch: any;
  handleSearch: any;
  setShowAds: any;
  handleRemoveResult: any;
  selectedPlace: any;
  setSelectedPlace: any;
}

const GradientAppBar = styled(AppBar)(({ theme }) => ({
  alignItems: "center",
  backgroundImage: `url(${backgroundImg})`,
  backgroundRepeat: "no-repeat",
  backgroundColor: colors.primary,
  backgroundPosition: "950px",
}));

const MainHeader = ({
  advanceSearchGlobal,
  setAdvanceSearchGlobal,
  advanceSearch,
  setAdvanceSearch,
  handleSearch,
  setShowAds,
  handleRemoveResult,
  selectedPlace,
  setSelectedPlace,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { classes } = useMainHeaderStyles();
  const { categoryShortDetails } = useGetAllCategoryShortDetails();
console.log("advanceSearchGlobal", advanceSearchGlobal)
  const searchStatus = useAppSelector(selectSearchStatus);
  const places = useAppSelector(selectAllPlaces);
  const currentAccount = useAppSelector(selectCurrentAccount);
  const [advanceSearchIcons, setAdvanceSearchIcons] = useState<string>("");

  const [showAdvanceSearch, setShowAdvanceSearch] = useState(false);

  const [category, setCategory] = useState<AdvanceSearchFilterModel>();
  const [categories, setCategories] = useState<CategoryShortDetail[]>(
    categoryShortDetails?.filter((x, i) => {
      const find = category?.categoryIds.find((y) => x.id === y);
      if (!find) {
        return;
      }

      return find;
    }) ?? []
  );

  const handleGlobalInputChange = (event: { target: { value: any } }) => {
    const inputCompanyName = event.target.value;

    setAdvanceSearchIcons(inputCompanyName);

    if (inputCompanyName.length >= 3) {
      setAdvanceSearchGlobal((prevState: any) => ({
        ...prevState,
        companyName: inputCompanyName,
      }));
      setShowAds(true);
    } else {
      setAdvanceSearchGlobal((prevState: any) => ({
        ...prevState,
        companyName: "",
      }));
      setShowAds(false);
      dispatch(resetSearch());
    }
  };

  const handleAdvanceInputChange = (event: {
    target: { value: any; name: string };
  }) => {
    const inputCompanyName = event.target.value;

    setAdvanceSearchIcons(inputCompanyName);

    if (event.target.name === "global_search") {
      if (inputCompanyName.length >= 3) {
        setAdvanceSearchGlobal((prevState: any) => ({
          ...prevState,
          companyName: inputCompanyName,
        }));
        setShowAds(true);
      } else {
        setAdvanceSearchGlobal((prevState: any) => ({
          ...prevState,
          companyName: "",
        }));
        setShowAds(false);
        dispatch(resetSearch());
      }
    }
    setAdvanceSearch((prevState: any) => ({
      ...prevState,
      companyName: inputCompanyName,
    }));
  };

  const handlePlaceChange = (event: any, newValue: any) => {
    setSelectedPlace(newValue);
    setAdvanceSearch((prevState: any) => ({
      ...prevState,
      placeIds: newValue.map((option: any) => option.id),
    }));
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickAdvanceSearch = () => {
    dispatch(getAllPlaces());
    dispatch(getAllCategories());
    setShowAdvanceSearch(true);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleButtonClick = (route: string) => {
    navigate(route);
    dispatch(resetCompany());
  };
  const handleCloseIconClick = () => {
    setAdvanceSearchIcons("");
    setAdvanceSearch(INIT_ADVANCE_SEARCH);
    setAdvanceSearchGlobal(INIT_ADVANCE_SEARCH);
    dispatch(resetSearch());
  };
  useEffect(() => {
    if (advanceSearchGlobal.companyName.length >= 3) {
      if(currentAccount){
        dispatch(getCompaniesAuthorized(advanceSearchGlobal));
      }else{
        dispatch(getCompaniesUnauthorized(advanceSearchGlobal));
        console.log("advancesearchglobal_useeffect", advanceSearchGlobal)
      }
      
    }
  }, [advanceSearchGlobal]);

  const [inputFocus, setInputFocus] = useState(false);

  return (
    <>
      <GradientAppBar
        position="static"
        sx={{
          height: showAdvanceSearch ? 280 : 150,
          backgroundSize: showAdvanceSearch ? "15%" : "10%",
        }}
      >
        <Toolbar
          id="mainHeader_toolbar"
          sx={{
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Grid container>
            <Grid
              item
              md={6}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                padding: "8px",
                alignItems: "center",
              }}
            >
              <img
                id="mainHeader_img_logo"
                src={logoFull}
                className={classes.logo}
                style={{ marginLeft: currentAccount ? "50px" : "0" }}
              />
              <Typography variant="h4" sx={{ marginLeft: "5px" }}>
                {t("header.telekomBl")}
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "10px",
              }}
            >
              <Button
                id="mainHeader_button_home"
                color="inherit"
                onClick={() => handleButtonClick(routes.home)}
                className={classes.button}
                disableRipple
              >
                <Typography variant="body1" sx={{ textTransform: "none" }}>
                  {t("header.homePage")}
                </Typography>
              </Button>
              <Button
                id="mainHeader_button_services"
                color="inherit"
                onClick={handleClick}
                className={classes.button}
                disableRipple
              >
                <Typography variant="body1" sx={{ textTransform: "none" }}>
                  {t("header.services")}
                </Typography>
              </Button>
              <Popover
                id="mainHeader_popover_services"
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                sx={{
                  "& .MuiPaper-root": {
                    backgroundColor: colors.primary,
                    border: `1px solid white`,
                  },
                }}
              >
                <MenuList>
                  <MenuItem
                    id="mainHeader_menu-item_update"
                    onClick={() => handleButtonClick(routes.updateData)}
                    sx={{
                      color: "white",
                      "&:hover": {
                        backgroundColor: "white",
                        color: colors.primary,
                      },
                    }}
                  >
                    <Typography variant="body1" sx={{ textTransform: "none" }}>
                      {t("header.updateData")}
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    id="mainHeader_menu-item_advertisement"
                    onClick={() => handleButtonClick(routes.advertisement)}
                    sx={{
                      color: "white",
                      "&:hover": {
                        backgroundColor: "white",
                        color: colors.primary,
                      },
                    }}
                  >
                    <Typography variant="body1" sx={{ textTransform: "none" }}>
                      {t("header.advertisement")}
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    id="mainHeader_menu-item_digital-marketing"
                    onClick={() => handleButtonClick(routes.digitalMarketing)}
                    sx={{
                      color: "white",
                      "&:hover": {
                        backgroundColor: "white",
                        color: colors.primary,
                      },
                    }}
                  >
                    <Typography variant="body1" sx={{ textTransform: "none" }}>
                      {t("header.digitalMarketing")}
                    </Typography>
                  </MenuItem>
                </MenuList>
              </Popover>
              <Button
                id="mainHeader_button_editions"
                color="inherit"
                className={classes.button}
                onClick={() => handleButtonClick(routes.editions)}
              >
                <Typography variant="body1" sx={{ textTransform: "none" }}>
                  {t("header.editions")}
                </Typography>
              </Button>
              <Button
                id="mainHeader_button_aboutUs"
                color="inherit"
                className={classes.button}
                onClick={() => handleButtonClick(routes.about)}
              >
                <Typography
                  variant="body1"
                  sx={{
                    textTransform: "none",
                  }}
                >
                  {t("header.aboutUs")}
                </Typography>
              </Button>
              <Button
                id="mainHeader_button_contact"
                color="inherit"
                className={classes.button}
                onClick={() => handleButtonClick(routes.contact)}
              >
                <Typography variant="body1" sx={{ textTransform: "none" }}>
                  {t("header.contact")}
                </Typography>
              </Button>
              <LanguageSwitcher />
            </Grid>
            {!showAdvanceSearch && (
              <Grid item md={12} className={classes.inputGrid}>
                <CustomOutlinedInput
                  id="mainHeader_input_company-name"
                  name="global_search"
                  label={t("header.companyNameInput")}
                  width="40%"
                  startAdornment={
                    inputFocus || advanceSearchIcons !== "" ? (
                      <SearchIcon className={classes.searchIcon} />
                    ) : null
                  }
                  endAdornment={
                    advanceSearchIcons !== "" ? (
                      <CloseIcon
                        onClick={handleCloseIconClick}
                        className={classes.closeIcon}
                      />
                    ) : null
                  }
                  height="40px"
                  value={advanceSearchIcons}
                  onFocus={() => setInputFocus(true)}
                  onBlur={() => setInputFocus(false)}
                  onChange={handleGlobalInputChange}
                />
              </Grid>
            )}

            {showAdvanceSearch ? (
              <Grid
                container
                sx={{ px: 10, py: 2 }}
                spacing={5}
                justifyContent="center"
              >
                <Grid container spacing={1} mt={2}>
                  <Grow in={showAdvanceSearch} timeout={500}>
                    <Grid item md={12} className={classes.inputGrid} mb={1}>
                      <CustomOutlinedInput
                        id="mainHeader_input_company-name"
                        name="global_search"
                        label={t("header.companyNameInput")}
                        width="80%"
                        startAdornment={
                          inputFocus || advanceSearchIcons !== "" ? (
                            <SearchIcon className={classes.searchIcon} />
                          ) : null
                        }
                        endAdornment={
                          advanceSearchIcons !== "" ? (
                            <CloseIcon
                              onClick={handleCloseIconClick}
                              className={classes.closeIcon}
                            />
                          ) : null
                        }
                        onFocus={() => setInputFocus(true)}
                        onBlur={() => setInputFocus(false)}
                        height="40px"
                        value={advanceSearchIcons}
                        onChange={handleAdvanceInputChange}
                      />
                    </Grid>
                  </Grow>
                  <Grow in={showAdvanceSearch} timeout={600}>
                    <Grid
                      item
                      md={6}
                      xs={12}
                      mb={1}
                      sx={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <SelectCategories
                        categories={categories}
                        categoryShortDetails={categoryShortDetails}
                        advanceSearch={advanceSearch}
                        setAdvanceSearch={setAdvanceSearch}
                        setCategories={setCategories}
                      />
                    </Grid>
                  </Grow>
                  <Grow in={showAdvanceSearch} timeout={1000}>
                    <Grid
                      item
                      md={6}
                      xs={12}
                      mb={1}
                      sx={{ display: "flex", justifyContent: "flex-start" }}
                    >
                      <Autocomplete
                        sx={{ width: "80%" }}
                        limitTags={1}
                        size="small"
                        id="mainHeader_autocomplete_places"
                        options={places ? places : []}
                        multiple
                        getOptionLabel={(option) => option.name}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        renderInput={(params) => (
                          <TextField
                            className={classes.autocompleteTextField}
                            {...params}
                            variant="outlined"
                            fullWidth
                            label={t("header.place") ?? ""}
                            sx={{
                              fontSize: "5px",
                              "& label.Mui-focused": {
                                color: "white",
                              },
                              "& .MuiInputLabel-outlined": {
                                transition: "all 0.2s",
                              },
                              "& .MuiInputLabel-outlined.MuiInputLabel-shrink":
                                {
                                  transform:
                                    "translate(14px, -13px) scale(0.75)",
                                },
                            }}
                          />
                        )}
                        className={classes.autocomplete}
                        onChange={handlePlaceChange}
                        disablePortal
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={
                                <CheckBoxOutlineBlankIcon fontSize="small" />
                              }
                              checkedIcon={<CheckBoxIcon fontSize="small" />}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.name}
                          </li>
                        )}
                        disableCloseOnSelect
                      />
                    </Grid>
                  </Grow>
                  <Grid
                    item
                    md={6}
                    xs={12}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <AppButton
                      id="mainHeader_button_search"
                      label={t("buttons.clearSearch")}
                      color={colors.red}
                      hover={colors.red}
                      onClick={handleRemoveResult}
                      startIcon={<DeleteIcon />}
                      minWidth="150px"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                    sx={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <AppButton
                      id="mainHeader_button_search"
                      label={t("buttons.search")}
                      color={colors.secondary}
                      hover={colors.secondary}
                      onClick={handleSearch}
                      minWidth="150px"
                      startIcon={
                        searchStatus !== "Pending" ? (
                          <SearchIcon />
                        ) : (
                          <CircularProgress size={"16px"} />
                        )
                      }
                      disabled={searchStatus !== "Pending" ? false : true}
                    />
                  </Grid>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    sx={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <ArrowBackIcon
                      id="mainHeader_icon_arrow-back"
                      onClick={() => setShowAdvanceSearch(false)}
                      className={classes.arrowBackIcon}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grow in={showAdvanceSearch === false}>
                <Grid
                  item
                  md={12}
                  justifyContent="center"
                  textAlign="center"
                  className={classes.advanceSearchWrapper}
                >
                  <Typography
                    className={classes.typography}
                    variant="body1"
                    gutterBottom
                    onClick={() => handleClickAdvanceSearch()}
                  >
                    <SearchIcon className={classes.searchIconFilters} />
                    {t("header.advanceSearchLabel")}
                  </Typography>
                </Grid>
              </Grow>
            )}
          </Grid>
        </Toolbar>
      </GradientAppBar>
    </>
  );
};

export default MainHeader;
