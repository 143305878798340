import { FormControl, FormHelperText, TextField } from "@mui/material";
import { ChangeEvent, useRef, useState } from "react";
import { useCustomOutlinedInputStyles } from "./customOutlinedInputStyles";

interface Props {
  label?: any;
  value?: any;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  error?: boolean;
  errorMessage?: any;
  disabled?: boolean;
  inputComponent?: any;
  name?: string;
  endAdornment?: any;
  id?: string;
  autofocus?: boolean;
  helperText?: any;
  height?: any;
  width?: string;
  readOnly?: boolean;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => any;
  required?: boolean;
  placeholder?: string;
  startAdornment?: any;
  multiline?: boolean;
  maxRows?: number;
  rows?: any;
  minRows?: any;
  inputTitleColor?: any;
  boxShadow?: any;
  inputProps?: any;
  fontWeight?: any;
  count?: any;
  labelColor?: any;
  validate?: boolean;
  onFocus?: any;
  onBlur?: any;
}

const CustomOutlinedInput = ({
  onChange,
  error,
  errorMessage,
  helperText,
  value,
  placeholder,
  id,
  label,
  rows,
  multiline,
  inputProps,
  count,
  name,
  width,
  labelColor,
  disabled,
  validate,
  startAdornment,
  endAdornment,
  onFocus,
  onBlur,
  type,
}: Props) => {
  const { classes } = useCustomOutlinedInputStyles();
  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);
  const [isValid, setIsValid] = useState(true);

  return (
    <FormControl
      variant="outlined"
      className={
        error === true ? classes.formControlError : classes.formControl
      }
      fullWidth
    >
      <TextField
        type={type}
        disabled={disabled}
        name={name}
        onFocus={onFocus}
        onBlur={onBlur}
        size="small"
        autoComplete="off"
        id={id}
        label={label}
        variant="outlined"
        sx={{
          width: width,
          textAlign: "center",
          margin: "0 auto",
          color: "white",
          "& label.Mui-focused": {
            color: labelColor ? labelColor : "white",
          },
          "& .MuiInputLabel-outlined": {
            transition: "all 0.2s",
          },
          "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
            transform: "translate(14px, -12px) scale(0.75)",
          },
        }}
        InputProps={{
          className: `${classes.input}`,
          startAdornment: startAdornment,
          endAdornment: endAdornment,
          autoComplete: 'off'
        }}
        rows={rows}
        onChange={onChange}
        multiline={multiline}
        inputProps={{ ...inputProps, maxLength: 1000 }}
        placeholder={placeholder}
        inputRef={inputRef}
        value={value}
        error={!isValid}
      />
      <FormHelperText>{helperText}</FormHelperText>
      {error === true && (
        <span className={classes.errorMessage}>{errorMessage}</span>
      )}
    </FormControl>
  );
};

export default CustomOutlinedInput;
