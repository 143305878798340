import { Dispatch } from "@reduxjs/toolkit";
import MySitePhotoModel from "../../models/mySite/mySitePhotoModel";
import MySiteTextModel from "../../models/mySite/mySiteTextModel";
import MySitePhoneModel from "../../models/mySite/mySitePhoneModel";
import MySiteVideoModel from "../../models/mySite/mySiteVideoModel";
import MySiteSocialMediaModel from "../../models/mySite/mySiteSocialMediaModel";
import MySiteGalleryModel from "../../models/mySite/mySiteGalleryModel";
import MySiteSliderModel from "../../models/mySite/mySiteSliderModel";
import {
  getCompanyWebsite,
  removeWebsitePartition,
  resetMySiteGallery,
  resetMySitePhone,
  resetMySitePhoto,
  resetMySiteSlider,
  resetMySiteSocialMedia,
  resetMySiteText,
  resetMySiteVideo,
} from "../../slices/mySiteSlice";
import { PartitionTypeText } from "../../models/enums/partitionTypeText";

export const toKey = (orderNumber: number) => {
  switch (orderNumber) {
    case 1:
      return "first";
    case 2:
      return "second";
    case 3:
      return "third";
    case 4:
      return "fourth";
    case 5:
      return "fifth";
    case 6:
      return "sixth";
    case 7:
      return "seventh";
    case 8:
      return "eight";
    default:
      return "";
  }
};

export const findSocialMediaType = (name: string): number | null => {
  switch (name.toLowerCase()) {
    case "facebook":
      return 0;
    case "instagram":
      return 1;
    case "youtube":
      return 2;
    case "linkedin":
      return 3;
    case "tiktok":
      return 4;
    case "booking.com":
      return 5;
    case "other":
      return 6;
    default:
      return null;
  }
};

export const handleRemovePartition = (
  dispatch: Dispatch<any>,
  selectedItems: {
    [key: string]: {
      value: string | null;
      orderNumber: number;
      id: string | null;
    };
  },
  setSelectedItems: React.Dispatch<
    React.SetStateAction<{
      [key: string]: {
        value: string | null;
        orderNumber: number;
        id: string | null;
      };
    }>
  >,

  cubeType: string,
  section: any,
  sectionType: string,
  setMySitePhotoSection: React.Dispatch<
    React.SetStateAction<MySitePhotoModel[]>
  >,
  setMySiteTextSection: React.Dispatch<React.SetStateAction<MySiteTextModel[]>>,
  setMySitePhoneSection: React.Dispatch<
    React.SetStateAction<MySitePhoneModel[]>
  >,
  setMySiteVideoSection: React.Dispatch<
    React.SetStateAction<MySiteVideoModel[]>
  >,
  setMySiteSocialMediaSection: React.Dispatch<
    React.SetStateAction<MySiteSocialMediaModel[]>
  >,
  setMySiteGallerySection: React.Dispatch<
    React.SetStateAction<MySiteGalleryModel[]>
  >,
  setMySiteSliderSection: React.Dispatch<
    React.SetStateAction<MySiteSliderModel[]>
  >
) => {
  if (
    !selectedItems[cubeType]?.value ||
    selectedItems[cubeType]?.value !== sectionType
  )
    return;

  const partitionToRemove = section.find(
    (partition: any) =>
      partition.orderNumber === selectedItems[cubeType]?.orderNumber
  );

  if (partitionToRemove) {
    const { id } = partitionToRemove;
    const updatedSelectedPartition = section.filter(
      (partition: any) =>
        partition.orderNumber !== selectedItems[cubeType]?.orderNumber
    );
    switch (sectionType) {
      case PartitionTypeText.Photo:
        setMySitePhotoSection(updatedSelectedPartition);
        break;
      case PartitionTypeText.Text:
        setMySiteTextSection(updatedSelectedPartition);
        break;
      case PartitionTypeText.Video:
        setMySiteVideoSection(updatedSelectedPartition);
        break;
      case PartitionTypeText.Phone:
        setMySitePhoneSection(updatedSelectedPartition);
        break;
      case PartitionTypeText.SocialMedia:
        setMySiteSocialMediaSection(updatedSelectedPartition);
        break;
      case PartitionTypeText.Gallery:
        setMySiteGallerySection(updatedSelectedPartition);
        break;
      case PartitionTypeText.Slider:
        setMySiteSliderSection(updatedSelectedPartition);
        break;
      default:
        break;
    }

    dispatch(removeWebsitePartition({ websitePartitionId: id }));

    setSelectedItems((prevState: any) => {
      const updatedItems = { ...prevState };
      const keyToUpdate = Object.keys(prevState).find(
        (key) => prevState[key].orderNumber === partitionToRemove.orderNumber
      );
      if (keyToUpdate) {
        updatedItems[keyToUpdate] = {
          ...prevState[keyToUpdate],
          value: null,
        };
      }
      return updatedItems;
    });
  }
};

export const handleClearItem = (
  orderNumber: number,
  type: string,
  id: string,
  selectedItems: any,
  setSelectedItems: React.Dispatch<
    React.SetStateAction<{
      [key: string]: {
        value: string | null;
        orderNumber: number;
        id: string | null;
      };
    }>
  >,
  setMySitePhotoSection: React.Dispatch<
    React.SetStateAction<MySitePhotoModel[]>
  >,
  setMySiteTextSection: React.Dispatch<React.SetStateAction<MySiteTextModel[]>>,
  setMySitePhoneSection: React.Dispatch<
    React.SetStateAction<MySitePhoneModel[]>
  >,
  setMySiteVideoSection: React.Dispatch<
    React.SetStateAction<MySiteVideoModel[]>
  >,
  setMySiteSocialMediaSection: React.Dispatch<
    React.SetStateAction<MySiteSocialMediaModel[]>
  >,
  setMySiteGallerySection: React.Dispatch<
    React.SetStateAction<MySiteGalleryModel[]>
  >,
  setMySiteSliderSection: React.Dispatch<
    React.SetStateAction<MySiteSliderModel[]>
  >,
  dispatch: Dispatch<any>
) => {
  dispatch(removeWebsitePartition({ websitePartitionId: id }));

  const keyToUpdate = Object.keys(selectedItems).find(
    (key) => selectedItems[key].orderNumber === orderNumber
  );
  if (keyToUpdate) {
    setSelectedItems((prevState) => ({
      ...prevState,
      [keyToUpdate]: {
        ...prevState[keyToUpdate],
        value: null,
      },
    }));

  }
  if (orderNumber === undefined || orderNumber === null) {
    return;
  }
  switch (type) {
    case PartitionTypeText.Photo:
      setMySitePhotoSection((prevSections) =>
        prevSections.filter((section) => section.orderNumber !== orderNumber)
      );
      dispatch(resetMySitePhoto());
      break;
    case PartitionTypeText.Text:
      setMySiteTextSection((prevSections) =>
        prevSections.filter((section) => section.orderNumber !== orderNumber)
      );
      dispatch(resetMySiteText());
      break;
    case PartitionTypeText.Phone:
      setMySitePhoneSection((prevSections) =>
        prevSections.filter((section) => section.orderNumber !== orderNumber)
      );
      dispatch(resetMySitePhone());
      break;
    case PartitionTypeText.Video:
      setMySiteVideoSection((prevSections) =>
        prevSections.filter((section) => section.orderNumber !== orderNumber)
      );
      dispatch(resetMySiteVideo());
      break;
    case PartitionTypeText.SocialMedia:
      setMySiteSocialMediaSection((prevSections) =>
        prevSections.filter((section) => section.orderNumber !== orderNumber)
      );
      dispatch(resetMySiteSocialMedia());
      break;
    case PartitionTypeText.Gallery:
      setMySiteGallerySection((prevSections) =>
        prevSections.filter((section) => section.orderNumber !== orderNumber)
      );
      dispatch(resetMySiteGallery());
      break;
    case PartitionTypeText.Slider:
      setMySiteSliderSection((prevSections) =>
        prevSections.filter((section) => section.orderNumber !== orderNumber)
      );
      dispatch(resetMySiteSlider());
      break;
    default:
      break;
  }
};

export const handleSelectItem = (
  item: string,
  cube: string,
  selectedItems: any,
  setSelectedItems: React.Dispatch<
    React.SetStateAction<{
      [key: string]: {
        value: string | null;
        orderNumber: number;
        id: string | null;
      };
    }>
  >,
  setMySitePhotoSection: React.Dispatch<
    React.SetStateAction<MySitePhotoModel[]>
  >,
  setMySiteTextSection: React.Dispatch<React.SetStateAction<MySiteTextModel[]>>,
  setMySitePhoneSection: React.Dispatch<
    React.SetStateAction<MySitePhoneModel[]>
  >,
  setMySiteVideoSection: React.Dispatch<
    React.SetStateAction<MySiteVideoModel[]>
  >,
  setMySiteSocialMediaSection: React.Dispatch<
    React.SetStateAction<MySiteSocialMediaModel[]>
  >,
  setMySiteGallerySection: React.Dispatch<
    React.SetStateAction<MySiteGalleryModel[]>
  >,
  setMySiteSliderSection: React.Dispatch<
    React.SetStateAction<MySiteSliderModel[]>
  >,
  setIsOpenUploadImgModal: React.Dispatch<React.SetStateAction<boolean>>,
  handleClose: (cube: string) => void,
  EmptyGuid: string
) => {
  setSelectedItems((prevState) => {
    const newState = { ...prevState };
    newState[cube] = {
      value: item,
      orderNumber: prevState[cube].orderNumber,
      id: EmptyGuid,
    };
    return newState;
  });
  handleClose(cube);
  const newOrderNumber = selectedItems[cube].orderNumber;
  switch (item) {
    case PartitionTypeText.Text:
      setMySiteTextSection((prevSections) => [
        ...prevSections,
        {
          id: EmptyGuid,
          orderNumber: newOrderNumber,
          value: "",
        },
      ]);
      break;
    case PartitionTypeText.Phone:
      setMySitePhoneSection((prevSections: MySitePhoneModel[]) => [
        ...prevSections,
        {
          id: EmptyGuid,
          orderNumber: newOrderNumber,
          phones: [{ name: "", phoneNumber: "" }],
        },
      ]);
      break;
    case PartitionTypeText.Photo:
      setIsOpenUploadImgModal(true);
      setMySitePhotoSection((prevSections: MySitePhotoModel[]) => [
        ...prevSections,
        {
          id: EmptyGuid,
          orderNumber: newOrderNumber,
          base64Photo: "",
        },
      ]);
      break;
    case PartitionTypeText.Video:
      setMySiteVideoSection((prevSections: MySiteVideoModel[]) => [
        ...prevSections,
        {
          id: EmptyGuid,
          orderNumber: newOrderNumber,
          link: "",
        },
      ]);
      break;
    case PartitionTypeText.SocialMedia:
      setMySiteSocialMediaSection((prevSections: MySiteSocialMediaModel[]) => [
        ...prevSections,
        {
          id: EmptyGuid,
          orderNumber: newOrderNumber,
          socialMedias: [],
        },
      ]);
      break;
    case PartitionTypeText.Gallery:
      setMySiteGallerySection((prevSections: MySiteGalleryModel[]) => [
        ...prevSections,
        {
          id: EmptyGuid,
          orderNumber: newOrderNumber,
          base64Photos: [],
          isGallery: true,
        },
      ]);
      break;
    case PartitionTypeText.Slider:
      setMySiteSliderSection((prevSections: MySiteSliderModel[]) => [
        ...prevSections,
        {
          id: EmptyGuid,
          orderNumber: newOrderNumber,
          base64Photos: [],
          isSlider: true,
        },
      ]);
      break;
    default:
      break;
  }
};
